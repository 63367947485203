<template>
    <div>
        <div class="banner">
                <img src="../assets/banner.png" alt="" srcset="">
        </div>
         <div class="main">
           
        <div class="upload" v-if="show_chose">
			<img src="../assets/upload.png" mode="">
			<div style="text-align: center; font-size: 17px; color: #999;margin-top: 7px;">
				支持文件格式：word、pdf
			</div>
			<!-- <div class="upload_button"  @click="upload_chose">
				上传文件
			</div> -->
			 <el-upload
                        class="avatar-uploader"
                        action="https://www.kmdnkj.com/api/index/index/method/images.uploadbswords"
                        :show-file-list="false"
						:data="usertoken"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                <div class="upload_button">
					上传文件
				</div>
            </el-upload>
		</div>
		<div class="service_title text_left">
			<b>上传文件</b> 
		</div>
		<div class="file_list center" style="margin-top:20px" v-if="!show_chose">
				<img src="../assets/doc.png" alt="" srcset="">
				{{file_name}}
				<img src="../assets/delete.png" class="delete" @click="resign">
		</div>
		
		<div class="service_title text_left" >
			<b>备注</b> 
		</div>
		<textarea v-model="ps_text" placeholder="请输入您的备注"  class="ps-box"/>
		<div class="service_title" style="display: flex;">
			<b>邮箱</b><span style="color: red;">*</span> 
		</div>
		<input type="text" v-model="email" class="input-style" placeholder="请输入您的邮箱" />
		<div class="service_title" style="display: flex; align-items: center;">
			<b>同意协议</b>
			<img src="../assets/aqua.png" mode="" style="height: 20px; width: 22px; margin-left: 20px;" @click="agree = !agree" v-if="!agree">
			<img src="../assets/agree.png" mode="" style="height: 20px; width: 22px; margin-left: 20px;" @click="agree = !agree" v-else>
			<span style="font-size: 25upx; color: #999;" @click="get_Art">《标书人工审核功能协议》</span>
		</div>
		
		
		<button class="button-bottom" @click="submit">下单</button>



        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            agree:false,
            email:'',
			ps_text:'',
			usertoken:{
				token:''
			},
			file_id:'',
			file_name:'',
			file_type:'',
			show_chose:true
        }
	},
	created(){
		if(this.$local.get('token')){
			this.usertoken.token = this.$local.get('token')
		}
	},
    methods:{
		get_Art(){
			this.$router.push({path:'/artical',query:{id:3}})
		},
		submit(){
			if(!this.file_id){
				this.$message.error('没有上传文件')
			}else if(!this.email){
				this.$message.error('没有填写邮箱')
			}else if(!this.ps_text){
				this.$message.error('没有填写备注')
			}else if(!this.agree){
				this.$message.error('没用同意协议')
			}else{
				this.$api.userpost('cart.add',{product_id:1,type:2,},(res)=>{
					if(res.data.status){
						let data = {
							memo:this.ps_text,
							emailbs:this.email,
							orderbs:this.file_id,
							cart_ids:res.data.data
						}
						this.$api.userpost('order.create',data,(res)=>{
								if(res.data.status){
									if(res.data.data.order_amount == 0){
										this.$message({message:'下单成功',type:'success'})
										// setTimeout(()=>{
										// 	this.$router.go(-1)
										// },2000)
										location.reload()
									}else{
										let data = {
											name:'标书审核',
											money:res.data.data.order_amount,
											order_id:res.data.data.order_id,
											type:1//写死的
										}
										// this.$common.navTo('../major/pay?type=1&order_id='+res.data.data.order_id + '&money='+res.data.data.order_amount + '&name=DANBIRD标书审核')
										this.$router.push({path:'/pay',query:{order_detail:JSON.stringify(data)}})
								}
								}else{
									this.$message.error(res.data.msg)
								}
							})
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
			
		},
        handleAvatarSuccess(res, file) {
			console.log(res.data.status)
            if(res.status){
				this.file_id = res.data.image_id
				this.show_chose = false
			}else{
				this.$message.error(res.data.msg)
			}
			console.log(res)
        },
        beforeAvatarUpload(file) {
			console.log(file)
			this.file_name = file.name
		},
		resign(){
			this.file_id = ''
			this.file_name = ''
			this.show_chose = true
		}
    }
}
</script>
<style lang="scss" scoped>
    .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }

     .banner{
        margin: 0 auto;
        margin-top: 45px;
        height: 196px;
        width: 1200px;
    }
   
    .upload{
		margin:  0 auto;
		width: 620px;
		height: 278px;
		background: #FFFFFF;
		border: 2upx dashed #6FC7FB;
		box-shadow: 0px 2px 6px rgba(153, 153, 153, 0.16);
		opacity: 1;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.upload img{
		margin-top: 61px;
		height: 67px;
		width: 78px;
	}
	
	.upload_button{
		width: 210px;
		height: 49px;
		line-height: 49px;
		color: #FFF;
		text-align: center;
		background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
		opacity: 1;
		border-radius: 25px;
		font-size: 23px;
		margin-top: 32px;
	}
	
	.service_title{
		font-size: 23px;
		margin-top: 24px;
		margin-left: 269px;
	}
	
	.ps-box{
		font-size: 20px;
		margin: 0 auto;
		margin-top: 13px;
		width: 580px;
		padding: 20px 20px;
		height: 136px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 10px;
        border: none;
        outline-style: none;
	}
	
	.input-style{
		margin: 0 auto;
		margin-top: 20px;
		width: 577px;
		height: 41px;
		line-height: 61px;
		padding: 10px 20px;
		background: #FFFFFF;
		font-size: 20px;
		color: #BBB;
		border-radius: 10px;
        outline-style: none;
        border: none;
	}

    .button-bottom{
		margin-top: 41px;
		height: 50px;
        width: 260px;
		line-height: 50px;
		background-color: #144591;
		color: #FFF;
		font-size: 18px;
		text-align: center;
		border-radius: 25px;
        border: none;
	}

	.file_list{
		width: 620px;
		height: 82px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		img{
			margin-left: 20px;
			height: 51px;
			width: 47px;
		}
		font-weight: bold;
		.delete{
			margin-right: 20px;
			height: 27px;
			width: 27px;
		}
	}
</style>